import { parse, stringify } from 'query-string'
import React from 'react'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { css } from 'src/theme/styled'
import AbstractCard from 'src/retired/shared/AbstractCard'
import { useLocation, useParams } from 'src/navigation'
import { colors } from 'src/theme'
import { providerLogos } from 'src/theme/images'
import { getLanguageCode } from 'src/utils/languageUtils'
import Image from 'src/retired/elements/Image'
import Chip from 'src/retired/shared/Chip'
import Deed from 'src/entities/deed/model'
import Spacing from 'src/retired/elements/Spacing'
import SkillsChips from 'src/retired/shared/SkillsChips'
import TopLeft from 'src/retired/shared/DeedCard/TopLeft'
import Content from 'src/retired/shared/DeedCard/Content'
import { formatNumber } from 'src/utils/format'
import { resize } from 'src/utils/resize'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { getCauseIconData, IconData } from 'src/retired/shared/DeedCard/helper'

interface DeedCardProps {
  size: 'standard' | 'small' | 'variable'
  deed: Deed
  cardStyle?: object
  hidePartnerLogo?: boolean
  disableLink?: boolean
  hideLocation?: boolean
}
export type DeedCardElement = React.ReactElement<DeedCardProps>

const DeedCard: React.FC<DeedCardProps> = ({
  size = 'standard',
  deed,
  cardStyle,
  hidePartnerLogo = false,
  disableLink = false,
  hideLocation = false,
}): DeedCardElement => {
  const { t, i18n } = useTranslation('deedCard')
  const user = useSelector(selectCurrentUser)
  const { id, lite } = useParams<{ id: string; lite: string }>()
  const { pathname, search } = useLocation()
  const campaignId = pathname.includes('campaign') ? id : parse(search)?.campaign
  const previousDonation = parse(search).prevDonation
  const partnerLogo =
    deed.optedIn || user?.organization?.id === deed.partner?.id ? deed.partner?.logo || deed.partner?.mainPicture : null

  const image = deed.get('banner') || deed.get('mainPicture')

  const topRight =
    partnerLogo && !hidePartnerLogo ? (
      <View
        style={[
          css`
            width: ${size === 'small' ? '36' : '48'}px;
            height: ${size === 'small' ? '36' : '48'}px;
            border-radius: ${size === 'small' ? '18' : '24'}px;
            border: 1px solid #0000001a;
            position: absolute;
            top: 0;
            right: 0;
          `,
        ]}
      >
        <View
          style={[
            css`
              width: ${size === 'small' ? '36' : '48'}px;
              height: ${size === 'small' ? '36' : '48'}px;
              border-radius: ${size === 'small' ? '18' : '24'}px;
              overflow: hidden;
              background-color: ${colors.white};
              position: relative;
              align-items: center;
              justify-content: center;
            `,
          ]}
        >
          <Image
            source={{ uri: partnerLogo }}
            resizeMode="contain"
            style={{
              width: size === 'small' ? 34 : 46,
              height: size === 'small' ? 34 : 46,
              borderRadius: size === 'small' ? 18 : 24,
              overflow: 'hidden',
            }}
          />
        </View>
      </View>
    ) : null

  const location = deed.getShortLocation()

  const bottomLeft = !hideLocation && !!location && (
    <Chip type="square" backgroundColor="rgba(255, 255, 255, 0.3)" textColor="white" textSize={12}>
      {location}
    </Chip>
  )

  const baseCurrencyCode = deed.currencyCode || 'USD'

  const bottomRight = (
    <View
      style={[
        css`
          flex-direction: row;
          margin-right: 1px;
        `,
      ]}
    >
      {deed.type === 'Campaign' &&
      deed.matchingPercentage &&
      ((deed.matchingMaximumCurrencies &&
        (deed.matchingMaximumCurrencies[baseCurrencyCode] > 0 ||
          _.isNil(deed.matchingMaximumCurrencies[baseCurrencyCode]))) ||
        _.isNil(deed.matchingMaximumCurrencies)) ? (
        <Chip type="square" backgroundColor={colors.yellow} textColor="black">
          {t('matched', {
            percentage: formatNumber(deed.matchingPercentage / 100),
          }).toLocaleUpperCase(getLanguageCode(i18n.language))}
        </Chip>
      ) : null}
      {deed.creditAmountPerHour > 0 ? (
        <Spacing marginLeft={12}>
          <Chip type="square" backgroundColor={colors.yellow} textColor="black">
            {t`earnCredit`.toLocaleUpperCase(getLanguageCode(i18n.language))}
          </Chip>
        </Spacing>
      ) : null}
      {deed.featured ? (
        <Spacing marginLeft={12}>
          <Chip type="square" backgroundColor={colors.primaryBlue} textColor="white">
            {t`featured`.toLocaleUpperCase(getLanguageCode(i18n.language))}
          </Chip>
        </Spacing>
      ) : null}
    </View>
  )

  const wrapperProps: any = {
    style: cardStyle,
    'data-date': t('date:dayMonthYearDigits', {
      date: deed.virtual
        ? deed.startingAt || deed.createdAt
        : {
            value: deed.startingAt || deed.createdAt,
            timeZone: deed.timeZone,
          },
    }),
  }

  const getFallbackIconData = (): IconData => {
    const iconData = getCauseIconData(deed.categories)
    return iconData
  }

  return (
    <AbstractCard
      wrapperProps={wrapperProps}
      size={size}
      title={deed.name}
      topLeft={<TopLeft deed={deed} size={size} />}
      topRight={topRight}
      bottomLeft={bottomLeft}
      bottomRight={bottomRight}
      image={image && resize(image, '960x540')}
      fallbackIconData={!image && getFallbackIconData()}
      link={
        !disableLink
          ? `${lite ? '/lite' : ''}/deeds/${deed.get('id')}?${stringify({
              campaign: campaignId,
              prevDonation: previousDonation,
            })}`
          : undefined
      }
    >
      {deed.type === 'Project' && (
        <Spacing marginTop={24} marginBottom={-1} style={{ minHeight: 36 }}>
          <SkillsChips deed={deed} />
        </Spacing>
      )}
      <Content deed={deed} />
      <Spacing marginBottom={22} />
      {deed.externalSource && (
        <View
          style={[
            css`
              display: flex;
              align-items: center;
              position: absolute;
              bottom: -10px;
              width: 100%;
            `,
          ]}
        >
          <Image
            style={{ width: 100, height: 20 }}
            backgroundPosition="center"
            backgroundSize="contain"
            backgroundRepeat="no-repeat"
            source={providerLogos[`${deed.externalSource}Logo` as keyof typeof providerLogos]}
          />
        </View>
      )}
    </AbstractCard>
  )
}
export default DeedCard
