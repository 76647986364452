/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { styled } from 'src/theme/styled'
import { useDeedTheme, EmotionTheme } from 'src/theme/ThemeProvider'
import { Loading, Screen, ScrollView, Spacing } from 'src/retired/elements'
import { Body, Text } from 'src/retired/shared/Typography'
import Header from 'src/retired/shared/Header'
import BackArrowButton from 'src/retired/shared/BackArrowButton'
import { useInjectEpics } from 'src/utils/injectEpics'
import { useInjectReducer } from 'src/utils/injectReducer'
import { selectDonationCredits } from 'src/entities/donationCredit/selectors'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { currencies } from 'src/containers/modules/CurrencyFormat'
import { formatAmount } from 'src/utils/format'

import { DonationCreditsTableBody } from './DonationCreditsTableBody/DonationCreditsTableBody'
import { initAction } from './types'
import reducer from './reducer'
import epics from './epics'
import { selectError, selectLoading } from './selectors'
import { SummaryContent } from './SummaryContent/SummaryContent'

const Table = styled.View<object, EmotionTheme>`
  background-color: ${({ theme }) => theme.colors.white};
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-color: ${({ theme }) => theme.colors.gray02};
  border-left-width: 0;
  border-right-width: 0;
`

const Section = styled.View`
  padding-bottom: 25px;
`

const SectionTitle = styled.View<object, EmotionTheme>`
  padding: 10px ${({ theme }) => (theme.metrics?.isSmall ? '10px' : '25px')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const SummarySection = styled(Section)<object, EmotionTheme>`
  padding-bottom: ${({ theme }) => (theme.metrics?.isSmall ? '5px' : '15px')};
`

const SummarySectionContent = styled.View<object, EmotionTheme>`
  padding: 0 ${({ theme }) => (theme.metrics?.isSmall ? '10px' : '25px')};
  padding-top: 25px;
  display: flex;
  flex-direction: ${({ theme }) => (theme.metrics?.isSmall ? 'column' : 'row')};
  justify-content: space-between;
  align-items: ${({ theme }) => (theme.metrics?.isSmall ? 'flex-start' : 'center')};
`

const sortDonationCreditsByExpiryDate = (a, b) => {
  if (!a.expiryDate) {
    return Infinity
  }

  if (a.expiryDate < b.expiryDate) {
    return -1
  }

  if (a.expiryDate > b.expiryDate) {
    return 1
  }

  return 0
}

// 2 weeks in milliseconds
const EXPIRY_WINDOW_OFFSET = 1000 * 60 * 60 * 24 * 7 * 2

const filterDonationCreditsExpiringSoon = (donationCredit) => {
  if (!donationCredit.expiryDate) {
    return false
  }

  return (
    donationCredit.expiryDate.valueOf() >= Date.now() &&
    donationCredit.expiryDate.valueOf() <= Date.now() + EXPIRY_WINDOW_OFFSET
  )
}

const reduceDonationCreditValuesByCurrencyCode = (values, donationCredit) => {
  values[donationCredit.currencyCode] = {
    currencyCode: donationCredit.currencyCode,
    creditAmount: values[donationCredit.currencyCode]
      ? values[donationCredit.currencyCode].creditAmount + donationCredit.creditAmount
      : donationCredit.creditAmount,
  }

  return values
}

const DonationCredits = (): JSX.Element => {
  useInjectEpics({ key: 'donationCredits', epics })
  useInjectReducer({ key: 'donationCredits', reducer })
  const { t } = useTranslation('donationCreditsProfile')
  const dispatch = useDispatch()
  const { colors, metrics } = useDeedTheme()

  const donationCredits = useSelector(selectDonationCredits).sort(sortDonationCreditsByExpiryDate)
  const donationCreditsExpiringSoon = donationCredits.filter(filterDonationCreditsExpiringSoon)
  const loading = useSelector(selectLoading)
  const error = useSelector(selectError)
  const user = useSelector(selectCurrentUser)
  const wallets = user?.donationCreditsWallets ? Object.values(user?.donationCreditsWallets) : null
  const donationCreditsExpiringSoonTotals =
    wallets && donationCreditsExpiringSoon.size > 0
      ? Object.values(
          donationCreditsExpiringSoon.reduce(
            reduceDonationCreditValuesByCurrencyCode,
            wallets.reduce((values, wallet) => {
              values[wallet.currencyCode] = { currencyCode: wallet.currencyCode, creditAmount: 0 }

              return values
            }, {})
          )
        )
      : null

  useEffect(() => {
    dispatch(initAction())
  }, [dispatch])

  const numbersFontSize = metrics.isSmall ? 22 : 36

  return (
    <Screen style={{ backgroundColor: colors.gray03 }}>
      <Header>
        <BackArrowButton backTo="/profile" />
        <Text
          fontSize={metrics.isSmall ? 16 : 20}
          lineHeight={metrics.isSmall ? 16 : 20}
          weight="500"
          marginLeft={15}
          numberOfLines={1}
        >
          {t`donationCredits`}
        </Text>
      </Header>
      <ScrollView>
        {loading ? (
          <Spacing paddingTop={30} paddingBottom={50}>
            <Loading fill={false} />
          </Spacing>
        ) : error ? (
          <Body lineHeight={20} center paddingTop={30}>
            {t`thereWasAnErrorLoadingYourCredits`}
          </Body>
        ) : donationCredits.size > 0 ? (
          <>
            <SummarySection>
              <SummarySectionContent>
                <SummaryContent title={t`totalEarned`}>
                  <Text fontSize={numbersFontSize}>
                    {wallets
                      ?.map(
                        (wallet) =>
                          `${currencies[wallet.currencyCode].symbol}${formatAmount(
                            wallet.creditAmount,
                            currencies[wallet.currencyCode].zeroDecimal
                          )}`
                      )
                      .join('\n')}
                  </Text>
                </SummaryContent>
                <SummaryContent title={t`balance`}>
                  <Text fontSize={numbersFontSize}>
                    {wallets
                      ?.map(
                        (wallet) =>
                          `${currencies[wallet.currencyCode].symbol}${formatAmount(
                            wallet.balance,
                            currencies[wallet.currencyCode].zeroDecimal
                          )}`
                      )
                      .join('\n')}
                  </Text>
                </SummaryContent>
                {donationCreditsExpiringSoon.size > 0 ? (
                  <SummaryContent title={t`expiring`}>
                    <Text fontSize={numbersFontSize} colour="coral">
                      {donationCreditsExpiringSoonTotals
                        ?.map((expiringCredit) => {
                          if (
                            expiringCredit.creditAmount >=
                            user.donationCreditsWallets[expiringCredit.currencyCode]?.balance
                          ) {
                            return `${currencies[expiringCredit.currencyCode].symbol}${formatAmount(
                              user.donationCreditsWallets[expiringCredit.currencyCode].balance,
                              currencies[expiringCredit.currencyCode].zeroDecimal
                            )}`
                          }

                          return `${currencies[expiringCredit.currencyCode].symbol}${formatAmount(
                            expiringCredit.creditAmount,
                            currencies[expiringCredit.currencyCode].zeroDecimal
                          )}`
                        })
                        .join('\n')}
                    </Text>
                  </SummaryContent>
                ) : (
                  <SummaryContent />
                )}
              </SummarySectionContent>
            </SummarySection>
            {donationCreditsExpiringSoon.size > 0 ? (
              <Section>
                <SectionTitle>
                  <Text fontSize={18} weight="500">
                    {t`expiringSoon`}
                  </Text>
                </SectionTitle>
                <Table>
                  <DonationCreditsTableBody donationCredits={donationCreditsExpiringSoon} />
                </Table>
              </Section>
            ) : null}
            <Section>
              <SectionTitle>
                <Text fontSize={18} weight="500">
                  {t`allCredits`}
                </Text>
              </SectionTitle>
              <Table>
                <DonationCreditsTableBody donationCredits={donationCredits.reverse()} />
              </Table>
            </Section>
          </>
        ) : (
          <Body lineHeight={20} center marginTop={20} paddingLeft={20} paddingRight={20} marginBottom={25}>
            {t`youHaveNotReceivedAnyCreditsYet`}
          </Body>
        )}
      </ScrollView>
    </Screen>
  )
}

export default DonationCredits
