import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withFormik } from 'formik'
import { View, StyleSheet } from 'react-primitives'
import { withTranslation, Trans } from 'react-i18next'

import config from 'src/config'
import { Link, withRouter } from 'src/navigation'
import {
  ActivityIndicator,
  Alert,
  Divider,
  AppleSignInButton,
  Form,
  LinkButton,
  LinkText,
  Button,
  Text,
  TextField,
  Screen,
  Spacing,
  ScrollView,
} from 'src/retired/elements'
import { ShortOnly, TallOnly } from 'src/retired/elements/MediaQuery'
import NavigationHeader from 'src/retired/blocks/NavigationHeader'
import { injectReducer, injectEpics, validators, Platform, matchCompanySso } from 'src/utils'
import { colors, metrics } from 'src/theme'
import { selectCompaniesWithSSO } from 'src/entities/organization/selectors'
import { selectCookieConsentResponse } from 'src/containers/modules/CookieConsent/selectors'
import { showCookieConsentAction } from 'src/containers/modules/CookieConsent/actions'
import { selectIsAuthenticated } from 'src/entities/auth/selectors'
import { PageTitle } from 'src/components'
import api from 'src/entities/api'

import { selectAuthenticating, selectAuthenticatingApple, selectError } from './selectors'
import * as Actions from './actions'
import epics from './epics'
import reducer from './reducer'

const styles = StyleSheet.create({
  header: {
    marginTop: 10,
    marginBottom: 30,
  },
  field: {
    borderBottomWidth: 1,
    borderBottomColor: colors.mediumGray,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  error: {
    minHeight: 16,
    marginTop: 2,
    marginBottom: 5,
    textAlign: 'center',
  },
})

let ssoPrompt = null

export class Login extends PureComponent {
  componentDidMount() {
    this.props.actions.initAction()
  }

  render() {
    api.LOGGING_OUT = false

    const {
      companiesWithSSO,
      history,
      authenticating,
      authenticatingApple,
      actions,
      values,
      dirty,
      errors,
      touched,
      isValid,
      setFieldValue,
      setFieldTouched,
      t,
    } = this.props

    const handleSubmit = (e) => {
      if (e.preventDefault) {
        e.preventDefault()
      }
      if (dirty && isValid) {
        actions.authAction(values.email, values.password)
      }
    }
    const emailHost = values.email && values.email.split('@')[1]
    const company = emailHost && matchCompanySso(values.email, companiesWithSSO)
    const isDeedAllowedEmailDomain =
      !config.isProduction || (emailHost?.endsWith('joindeed.com') && values.email.includes('+'))

    if (company && !ssoPrompt && !isDeedAllowedEmailDomain) {
      ssoPrompt = setTimeout(() => {
        Alert.alert(t`pleaseUseSSOtoLogIn`, t('toLogInWithCompany', { companyName: company.name }), [
          { text: t`cancel`, style: 'cancel' },
          {
            text: t`accept`,
            onPress: () => {
              history.push(`/login/sso/${company.id}#${values.email}`)
            },
          },
        ])
        setFieldValue('email', '')
        setFieldTouched('email', false)
        ssoPrompt = null
      }, 500)
    }
    const smallScreen = metrics.screenHeight < 600
    const padding = smallScreen ? 15 : 25
    const showAppleSignIn = Platform.OS === 'ios'
    return (
      <Screen fixed>
        <PageTitle title={t`logIn`} />

        <ScrollView>
          <NavigationHeader transparent showLanguageSwitcher />
          <Screen middle center paddingHorizontal narrow>
            <View style={{ maxWidth: '100%' }}>
              <ShortOnly>
                <Spacing marginTop={5} />
              </ShortOnly>
              <TallOnly>
                <Text size={22} style={styles.header} center>
                  {t`common:welcomeBack`}
                </Text>
              </TallOnly>
              {showAppleSignIn ? (
                <Spacing marginBottom={25} style={{ alignSelf: 'stretch', alignItems: 'center' }}>
                  {authenticatingApple ? (
                    <Button size="small" fluid style={{ backgroundColor: '#000', width: 280 }}>
                      <ActivityIndicator color="#fff" />
                    </Button>
                  ) : (
                    <AppleSignInButton onPress={actions.appleLoginAction} />
                  )}
                </Spacing>
              ) : (
                <Spacing marginBottom={15} />
              )}
              <Text center>
                <Trans
                  t={t}
                  i18nKey="byContinuing"
                  values={{
                    termsText: t`termsOfUse`,
                    privacyText: t`privacyPolicy`,
                  }}
                  components={{
                    TermsLink: <LinkText to="/terms" color={colors.brandColor} />,
                    PrivacyLink: <LinkText to="/privacy" color={colors.brandColor} />,
                  }}
                />
              </Text>
              {showAppleSignIn ? <Divider marginBottom={20}>{t`common:or`}</Divider> : <Spacing marginBottom={20} />}
              <Form onSubmit={handleSubmit}>
                <View style={styles.field}>
                  <TextField
                    placeholder={t`email`}
                    onChangeText={setFieldValue}
                    onTouched={setFieldTouched}
                    onBlur
                    name="email"
                    value={values.email}
                    maxLength={50}
                    autoFocus={metrics.screenWidth > 640 && !values.email}
                    keyboardType="email-address"
                    autoCapitalize="none"
                  />
                </View>
                <Text color={colors.redDark} style={styles.error}>
                  {touched.email && errors.email}
                </Text>
                {!smallScreen || values.email ? (
                  <>
                    <View style={styles.field}>
                      <TextField
                        placeholder={t`password`}
                        onChangeText={setFieldValue}
                        onTouched={setFieldTouched}
                        name="password"
                        secureTextEntry
                        value={values.password}
                        maxLength={50}
                        autoFocus={!!(metrics.screenWidth > 640 && values.email)}
                        autoCapitalize="none"
                        onSubmitEditing={handleSubmit}
                      />
                    </View>
                    <Text color={colors.redDark} style={styles.error}>
                      {touched.password && errors.password}
                    </Text>
                  </>
                ) : null}
                <Button
                  type="submit"
                  onPress={handleSubmit}
                  disabled={!(dirty && isValid)}
                  color="primary"
                  fluid
                  style={{ marginTop: 15 }}
                >
                  {authenticating ? <ActivityIndicator color="#fff" /> : t`logIn`}
                </Button>
              </Form>
              <Spacing marginTop={padding + 5} style={{ alignSelf: 'stretch', alignItems: 'center' }}>
                <Link to="/login/forgot">
                  <Text size={15} underline>
                    {t`forgotPassword`}
                  </Text>
                </Link>
              </Spacing>
              <Divider marginTop={padding} marginBottom={padding} />
              <Text size={14} center>
                {t`loggingInAsACompanyEmployee`}
              </Text>
              <Spacing marginTop={15} marginBottom={20}>
                <LinkButton style={{ maxWidth: '100%' }} to="/login/sso" fluid>{t`singleSignOn`}</LinkButton>
              </Spacing>
              <View />
            </View>
          </Screen>
        </ScrollView>
      </Screen>
    )
  }
}
Login.propTypes = {
  companiesWithSSO: PropTypes.object,
  history: PropTypes.object,
  authenticating: PropTypes.bool,
  actions: PropTypes.object,
  values: PropTypes.object,
  dirty: PropTypes.bool,
  errors: PropTypes.object,
  touched: PropTypes.object,
  isValid: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
}

const withConnect = connect(
  (state) => ({
    isAuthenticated: selectIsAuthenticated(state),
    authenticating: selectAuthenticating(state),
    selectAuthenticatingApple: selectAuthenticatingApple(state),
    companiesWithSSO: selectCompaniesWithSSO(state),
    hasAcceptedCookies: selectCookieConsentResponse(state),
    error: selectError(state),
  }),
  (dispatch) => ({
    actions: bindActionCreators({ ...Actions, showCookieConsentAction }, dispatch),
  })
)
const withReducer = injectReducer({ key: 'login', reducer })
const withEpics = injectEpics({ key: 'login', epics })
const withForm = withFormik({
  validate: (values) => {
    const errors = {
      email: validators.notEmpty(values.email) || validators.isEmail(values.email),
      password: validators.notEmpty(values.password) || validators.minLength(6)(values.password),
    }
    const isValid = Object.values(errors).every((value) => !value)

    return isValid ? {} : errors
  },
  mapPropsToValues: ({ location }) => ({
    email: location.hash ? location.hash.substr(1) : '',
    password: '',
  }),
  validateOnBlur: true,
  validateOnChange: true,
  validateOnMount: true,
})

export default compose(withRouter, withReducer, withEpics, withConnect, withForm, withTranslation('loginScreen'))(Login)
