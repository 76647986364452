// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global __DEV__, __DETECTED_VIEWER_TIME_ZONE__, __DETECTED_VIEWER_COUNTRY__ */
import 'whatwg-fetch'
import { replace, LOCATION_CHANGE } from 'connected-react-router'
import { Observable } from 'rxjs'
import type { AnyAction, Store } from 'redux'
import { ActionsObservable } from 'redux-observable'
import i18n from 'i18next'
import { buildCustomLocale } from '@joindeed/i18n/src/buildCustomLocale'
import _ from 'lodash'

import { apolloClient } from 'src/entities/graphql'
import { PayrollSettingsDocument } from 'src/generated/graphql'
import User from 'src/entities/user/model'
import { getLanguageCode } from 'src/utils/languageUtils'
import { StoreAction, storeAction, updateAction } from 'src/entities/auth/actions'
import { STORE as STORE_AUTH } from 'src/entities/auth/constants'
import config from 'src/config'
import { bootstrapAction, brandAction } from 'src/containers/modules/PersistGate/actions'
import UserApi from 'src/entities/user/api'
import OrganizationApi from 'src/entities/organization/api'
import Api from 'src/entities/api'
import { ADD as ADD_USER, UPDATE as UPDATE_USER } from 'src/entities/user/constants'
import { selectIsNew } from 'src/entities/auth/selectors'
import { selectDeedId } from 'src/containers/screens/Deed/Authenticate/selectors'
import { selectCookieConsentResponse } from 'src/containers/modules/CookieConsent/selectors'
import { Platform, validators } from 'src/utils'
import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { selectLocalSetting } from 'src/localSettings/selectors'
import { setLocalSettingAction } from 'src/localSettings/actions'
import * as Sentry from 'src/utils/Sentry'
import { AddUser, updateAction as updateUserAction, UpdateUser } from 'src/entities/user/actions'
import { createUrlFromHost } from 'src/utils/createUrlFromHost'
import isDefaultAppOrigin from 'src/utils/isDefaultAppOrigin'
import truthy from 'src/utils/truthy'
import { State } from 'src/reducers'
import { getQuickRedirectToLoginUrl } from 'src/navigation/QuickRedirectToLogin'

const fetchOrganizationBrand = (action$: ActionsObservable<AnyAction>) =>
  action$
    .ofType('persist/REHYDRATE')
    .mergeMap(() => {
      if (Platform.OS !== 'web' || (window && isDefaultAppOrigin(window.location.host))) {
        return [brandAction()]
      }

      return OrganizationApi.fetchBrand().mergeMap((brand) => {
        const actions = []

        if (brand) {
          actions.push(setLocalSettingAction('brand', brand))
          actions.push(setLocalSettingAction('brandColor', brand.brandColor))
        } else {
          actions.push(setLocalSettingAction('brand', null))
        }

        actions.push(brandAction())

        return actions
      })
    })
    .catch((error) => {
      Sentry.captureException(error, { level: Sentry.Severity.Warning })

      return []
    })

const injectConfigurationFromCloudFront = (action$: ActionsObservable<AnyAction>) =>
  action$.ofType('persist/REHYDRATE').mergeMap(() =>
    Observable.from(
      fetch(Platform.OS === 'web' ? `${window.location.origin}/favicon.ico` : `${config.webUrl}/favicon.ico`, {
        method: 'HEAD',
      })
    )
      .mergeMap((response) => {
        const viewerCountry = response.headers.get('cloudfront-viewer-country') ?? ((__DEV__ && 'QPS') || undefined)
        const viewerTimezone =
          response.headers.get('cloudfront-viewer-time-zone') ??
          Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ??
          'America/New_York'

        Api.defaultHeaders['Viewer-Country'] = viewerCountry

        // eslint-disable-next-line no-global-assign
        __DETECTED_VIEWER_COUNTRY__ = viewerCountry
        // eslint-disable-next-line no-global-assign
        __DETECTED_VIEWER_TIME_ZONE__ = viewerTimezone

        return []
      })
      .catch((error) => {
        Sentry.captureException(error, { level: Sentry.Severity.Warning })

        return []
      })
  )

const redirectIfAuthenticated = (action$: ActionsObservable<AnyAction>) =>
  action$.ofType('persist/REHYDRATE').mergeMap(({ payload }) => {
    const actions = []
    const token = payload.entities?.auth?.token
    if (token) {
      actions.push(storeAction(token))
    }

    if (__DEV__ && Platform.OS !== 'web') {
      const route = payload.router?.location?.pathname || payload.router?.get('location')?.get('pathname')
      if (route && route !== '/') {
        actions.push(replace(route))
      }
    }
    actions.push(bootstrapAction())
    return actions
  })

const setApiDeedPathHeaderOnLocationChange = (action$) =>
  action$.ofType(LOCATION_CHANGE).mergeMap(
    ({
      payload: {
        location: { pathname, search },
      },
    }) => {
      Api.defaultHeaders['X-DEED-PATH'] = `${pathname}${search}` // don't include hash for security reasons
      return []
    }
  )

const setRequestTokenAndFetchUserAfterAuthentication = (action$: ActionsObservable<StoreAction>, store: Store<State>) =>
  action$.ofType(STORE_AUTH).mergeMap(({ token }) => {
    Api.defaultHeaders.Authorization = `Bearer ${token}`
    Api.authenticationFailed = () => {
      Api.LOGGING_OUT = true

      const state = store.getState()
      const username = selectLocalSetting(state, 'username')
      const locationState = state.get('router').get('location')
      const location = `${locationState.get('pathname')}${locationState.get('search')}`
      store.dispatch({ type: 'RESET' })
      store.dispatch(showErrorAction(i18n.t('common:yourSessionHasTimedOut')))
      store.dispatch(setLocalSettingAction('username', null))
      store.dispatch(setLocalSettingAction('userId', null))
      store.dispatch(setLocalSettingAction('organization', null))
      store.dispatch(setLocalSettingAction('brandColor', null))
      const cookieConsentResponse = selectCookieConsentResponse(state)
      if (cookieConsentResponse) {
        store.dispatch(setLocalSettingAction('previousLocation', location))
      } else if (window?.sessionStorage) {
        window.sessionStorage.setItem('previousLocation', location)
      }

      store.dispatch(replace(`${getQuickRedirectToLoginUrl()}${username ? `#${username}` : ''}`))
      Api.authenticationFailed = null
    }
    Api.setAuthorization = (tokenInner) => {
      Api.defaultHeaders.Authorization = `Bearer ${tokenInner}`
      store.dispatch(updateAction(tokenInner))
    }

    return UserApi.fetch('me')
      .mergeMap((action) => {
        const actions = [action]
        const { user } = action

        if (Platform.OS === 'web') {
          const defaultHost = new URL(config.webUrl).host
          const userAppVanityDomain = user?.organization?.appVanityDomain
          const testingPreviewDomain = '.app.testing.'

          /* 
            @NOTE-CH: We want to redirect the user to its own vanity domain or out from other companies vanity domains
                      If No vanity domain is set, then we want them to be on the default domain (e.g. app.joindeed.org)
          */
          const shouldRedirectToUserAppVanityDomain =
            Boolean(
              userAppVanityDomain &&
                !window?.location.host.match(userAppVanityDomain) &&
                !window?.location.host.match(testingPreviewDomain)
            ) || Boolean(!userAppVanityDomain && user.organization && !window?.location.host.match(defaultHost))

          if (shouldRedirectToUserAppVanityDomain) {
            const webUrl = createUrlFromHost(userAppVanityDomain || defaultHost)

            if (Platform.isFF) {
              // This is a hack workaround for a Firefox location state bug
              // https://bugzilla.mozilla.org/show_bug.cgi?id=1422334
              // eslint-disable-next-line no-self-assign
              window.location.hash = window.location.hash
            }
            window.location.href = `${webUrl}/login/token?d=${window.location.pathname}#${token}`

            return []
          }
        }

        return actions
      })
      .catch((e) =>
        !e.response || e.response.status === 401
          ? []
          : Observable.of(showErrorAction(i18n.t('common:couldNotFetchUser')))
      )
  })

const storeLoginInformation = (action$: ActionsObservable<AddUser>) =>
  action$.ofType(ADD_USER).mergeMap(({ user }) => {
    if (!user.me) {
      return []
    }
    const actions = []
    actions.push(setLocalSettingAction('userId', user.id))
    if (user.sso.username) {
      actions.push(setLocalSettingAction('username', user.sso.username))
      actions.push(setLocalSettingAction('organization', user?.organization?.id || null))
    } else if (!user.facebook.email) {
      actions.push(setLocalSettingAction('username', user.email))
    }
    const brand =
      user?.organization &&
      _.pick(user?.organization, [
        'name',
        'brief',
        'mainPicture',
        'logo',
        'website',
        'facebookPage',
        'instagram',
        'sso',
        'sso2',
        'providers',
        'emailDomains',
        'brandColor',
        'code',
        'appVanityDomain',
        'splashBackgroundImage',
      ]) // Copied from https://github.com/joindeed/api/blob/d046e1e7927f3a20f1b12e79d75780db3da6307b/server/api/organization/organization.controller.js#L3028
    actions.push(setLocalSettingAction('brand', brand ?? null))
    actions.push(setLocalSettingAction('brandColor', brand?.brandColor ?? null))
    return actions
  })

const redirectUserIfNeeded = (action$: ActionsObservable<AddUser>, store: Store<State>) =>
  action$.ofType(ADD_USER).mergeMap(({ user }) => {
    if (!user.me) {
      return []
    }

    const state = store.getState()
    const authenticateDeedId = selectDeedId(state)
    if (authenticateDeedId && user.email) {
      return [
        replace(
          user.deeds === 0
            ? `/deeds/${authenticateDeedId}/first`
            : !user.phone
            ? `/register/phone/${authenticateDeedId}`
            : `/deeds/${authenticateDeedId}/confirm`
        ),
      ]
    }
    const isNew = selectIsNew(state)
    if (isNew) {
      return []
    }

    const locationState = state.get('router').get('location')
    const location = `${locationState.get('pathname')}${locationState.get('search')}`

    const setLocation = setLocalSettingAction('previousLocation', location)
    if (user.status === 'pending' && user.emailVerificationToken) {
      return [replace('/register/company/confirm')]
    }
    if (validators.notEmpty(user.email) || validators.isEmail(user.email)) {
      return [replace('/register/email')]
    }

    // Don't interrupt important flows with unimportant onboarding steps
    if (location.includes('/campaign') || location.includes('/donate')) {
      return []
    }

    if (user.ratePending.length > 0) {
      return [replace({ pathname: '/deeds/rate', state: { location } })]
    }
    if (!user.location) {
      return [setLocation, replace('/register/location')]
    }
    if (!user.interests) {
      return [setLocation, replace('/register/interests')]
    }
    if (
      user.status === 'accepted' &&
      user.organization &&
      user.organization.departments.length > 0 &&
      !user.organizationDepartment
    ) {
      return [replace('/register/company/department')]
    }
    return []
  })

// On load of user entity, get user preferred locale from it and set i18next language
const setCustomLocale = (action$: ActionsObservable<AddUser | UpdateUser>) =>
  action$.ofType(ADD_USER, UPDATE_USER).mergeMap(({ type, user, ...rest }) => {
    if (!user.me) {
      return []
    }
    const properties = ('properties' in rest && rest?.properties) || []
    if (type === UPDATE_USER && !properties.includes('locale')) {
      return []
    }

    const baseLocale = user.locale || getLanguageCode(i18n.language)
    const organizationName = (
      user.getIn(['organization', 'code']) || user.getIn(['organization', 'name'])
    )?.toUpperCase()
    const newLocale = buildCustomLocale(baseLocale, {
      org: organizationName,
      // There's something spooky going on with the order of private segments in the fallback chain
      // Figure it out when we'd need location-specific overrides:
      // loc: user.get('location'),
    })
    if (newLocale !== i18n.language) {
      i18n.changeLanguage(newLocale)
    }
    return []
  })

// On load of user entity, get user donation credit if necessary
const fetchDonationCredit = (action$: ActionsObservable<AddUser>) =>
  action$.ofType(ADD_USER).mergeMap(({ user }) => {
    if (!user.me) {
      return []
    }

    if (!user.hasFeature('donationCredit')) {
      return []
    }

    return UserApi.myDonationCredit()
      .mergeMap((data) => {
        const updatedUserObject = {
          id: user.id,
          name: user.name,
          ...data,
        }

        return [updateUserAction(new User(updatedUserObject, true), Object.keys(updatedUserObject))]
      })
      .catch((e) =>
        !e.response || e.response.status === 401
          ? []
          : Observable.of(showErrorAction(i18n.t('common:couldNotFetchUser')))
      )
  })

// Pre-fetch payroll settings on load, not to add extra delay to the Donate screen
const fetchPayrollSettings = (action$: ActionsObservable<AddUser>) =>
  action$.ofType(ADD_USER).mergeMap(({ user }) => {
    if (user.me && user.hasFeature('payrollApi')) {
      void apolloClient.query({
        query: PayrollSettingsDocument,
      })
    }
    return []
  })

const fetchVolunteerTimeOffSummary = (action$: ActionsObservable<AddUser>) =>
  action$.ofType(ADD_USER).mergeMap(({ user }) => {
    if (!user.me || !user.hasFeature('volunteerTimeOff')) {
      return []
    }

    return UserApi.myVolunteerTimeOffSummary()
      .mergeMap((res) => {
        const updatedUserObject = {
          id: user.id,
          name: user.name,
          ...res.data,
        }
        return [updateUserAction(new User(updatedUserObject, true), Object.keys(updatedUserObject))]
      })
      .catch((e) =>
        !e.response || e.response.status === 401 ? [] : Observable.of(showErrorAction(i18n.t('common:anErrorOccurred')))
      )
  })

const resetApolloCache = (action$: ActionsObservable<{ type: 'RESET' }>) =>
  action$.ofType('RESET').mergeMap(() => {
    void apolloClient.clearStore()
    return []
  })

export default [
  fetchPayrollSettings,
  fetchOrganizationBrand,
  injectConfigurationFromCloudFront,
  redirectIfAuthenticated,
  setApiDeedPathHeaderOnLocationChange,
  setRequestTokenAndFetchUserAfterAuthentication,
  storeLoginInformation,
  redirectUserIfNeeded,
  setCustomLocale,
  fetchDonationCredit,
  fetchVolunteerTimeOffSummary,
  resetApolloCache,
].filter(truthy)
