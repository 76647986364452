import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { CauseMap } from 'src/entities/cause/reducer'
import { Loading, Row, ScrollView } from 'src/retired/elements'
import CountrySelector from 'src/components/CountrySelector'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { FilterItemContainer } from '../../common/FilterItemContainer'
import { ChipItemProps, ToggleableChipList } from '../../common/ToggleableChipList'

import { CauseFilters } from './CauseFilters'

export const DonateFilters = ({
  location,
  setLocation,
  setAreFiltersTouched,
  currentActiveCauses,
  donateTypesList,
  setDonateTypesList,
  causesOptions,
  setCausesOptions,
}: {
  location: string
  setLocation: (value: string) => void
  setAreFiltersTouched: (value: boolean) => void
  currentActiveCauses: CauseMap
  donateTypesList: ChipItemProps[]
  setDonateTypesList: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
  causesOptions: ChipItemProps[]
  setCausesOptions: React.Dispatch<React.SetStateAction<ChipItemProps[]>>
}) => {
  const { t } = useTranslation('searchScreen')

  const user = useSelector(selectCurrentUser)
  const isDonationFeatureEnabled = user?.hasFeature('donations')
  const isFundraiserFeatureEnabled = user?.hasFeature('fundraisers')

  return (
    <ScrollView style={{ paddingHorizontal: 14 }}>
      <FilterItemContainer title={t`location`} style={{ zIndex: 2 }} divider={false}>
        <CountrySelector
          width="100%"
          placeholder={t`selectALocation`}
          handleChangeLocation={(newLocation) => {
            if (!newLocation) {
              return
            }
            setLocation(newLocation)
            setAreFiltersTouched(true)
          }}
          initialLocationCode={location}
          setInitialLocationCode={setLocation}
          country={location}
          showStates
        />
      </FilterItemContainer>

      {isDonationFeatureEnabled && isFundraiserFeatureEnabled && (
        <FilterItemContainer title={t`type`}>
          <Row>
            <ToggleableChipList
              values={donateTypesList}
              setValues={(values) => {
                setDonateTypesList(values)
                setAreFiltersTouched(true)
              }}
            />
          </Row>
        </FilterItemContainer>
      )}

      <>
        {!causesOptions.length && (
          <View style={{ marginTop: 20 }}>
            <Loading fill={false} backgroundColor="transparent" />
          </View>
        )}

        <CauseFilters
          causesOptions={causesOptions}
          initCausesOptions={(values) => setCausesOptions(values)}
          onChangeValues={(values) => {
            setCausesOptions(values)
            setAreFiltersTouched(true)
          }}
          currentActiveCauses={currentActiveCauses}
        />
      </>
    </ScrollView>
  )
}
