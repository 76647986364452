import React from 'react'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { cloneDeep } from 'lodash'

import { Question, InputType } from 'src/entities/organization/model'
import { Label } from 'src/retired/shared/Typography'
import { Row } from 'src/retired/elements'

export type CurrentAnswers = Record<string, string[]>

export const validateAdditionalQuestionResponses = ({
  currentAnswers,
  additionalVolunteerTimeQuestions,
}: {
  currentAnswers: CurrentAnswers | undefined
  additionalVolunteerTimeQuestions: Question[]
}) => {
  if (!currentAnswers) {
    return false
  }

  const hasMissingMandatoryField = additionalVolunteerTimeQuestions.some((question) => {
    if (!question.isRequired) {
      return false
    }

    return !currentAnswers[question._id]?.length
  })

  return !hasMissingMandatoryField
}

const RadioQuestion = ({
  question,
  setCurrentAnswers,
  currentAnswers,
}: {
  question: Question
  setCurrentAnswers: (currentAnswers: CurrentAnswers) => void
  currentAnswers: CurrentAnswers
}) => (
  <div style={{ paddingBottom: 10 }}>
    <Row>
      <Label>{question?.title}</Label>
    </Row>

    {(question?.typeOptions ?? []).map((typeOption, index) => (
      <div style={{ marginTop: question.typeOptions.length - 1 === index ? -10 : 0 }}>
        <FormControlLabel
          label={<Label>{typeOption.title}</Label>}
          control={
            <Radio
              onChange={() => {
                if (!currentAnswers[question._id]?.length || currentAnswers[question._id]?.[0] !== typeOption.title) {
                  const currentAnswersClone = cloneDeep(currentAnswers)
                  currentAnswersClone[question._id] = [typeOption.title]
                  setCurrentAnswers(currentAnswersClone)
                }
              }}
              checked={currentAnswers[question._id].includes(typeOption.title)}
            />
          }
        />
      </div>
    ))}
  </div>
)

const renderQuestion = ({
  question,
  setCurrentAnswers,
  currentAnswers,
}: {
  question: Question
  setCurrentAnswers: (currentAnswers: CurrentAnswers) => void
  currentAnswers: CurrentAnswers
}) => {
  if (question.inputType === InputType.Radio) {
    return <RadioQuestion question={question} currentAnswers={currentAnswers} setCurrentAnswers={setCurrentAnswers} />
  }
  return null
}

export const AdditionalVolunteerTimeQuestions = ({
  additionalVolunteerTimeQuestions,
  setCurrentAnswers,
  currentAnswers,
}: {
  additionalVolunteerTimeQuestions: Question[]
  setCurrentAnswers: (currentAnswers: CurrentAnswers) => void
  currentAnswers: CurrentAnswers
}) => (
  <div>
    {additionalVolunteerTimeQuestions?.map((question) =>
      renderQuestion({ question, setCurrentAnswers, currentAnswers })
    )}
  </div>
)
