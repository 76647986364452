import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { EligibilityFormStatus, SortOrder, useVolunteerRewardsListQuery, VolunteerReward } from 'src/generated/graphql'
import { ActivityIndicator, Touchable } from 'src/retired/elements'
import { Body2 } from 'src/retired/shared/Typography'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { selectCurrentUser } from 'src/entities/user/selectors'

import { VolunteerRewardsTable } from './VolunteerRewardsTable'

export const VolunteerRewardsTableContainer = () => {
  const { t } = useTranslation('volunteerTimesProfile')
  const { colors } = useDeedTheme()

  const [isExpanded, setIsExpanded] = useState(false)
  const user = useSelector(selectCurrentUser)
  const isVolunteerRewardFeatureEnabled = user?.hasFeature('volunteerReward')

  const { data, error, loading } = useVolunteerRewardsListQuery({
    skip: !isVolunteerRewardFeatureEnabled,
    variables: {
      where: {
        user: { equals: user?.id },
        formStatus: {
          in: [EligibilityFormStatus.Eligible, EligibilityFormStatus.NotEligible, EligibilityFormStatus.NotRequired],
        },
      },
      orderByList: [
        {
          formSubmittedAt: SortOrder.Desc,
        },
        {
          createdAt: SortOrder.Desc,
        },
      ],
      skipVolunteerTimeStatus: true,
    },
  })

  const volunteerRewards = data?.volunteerRewards?.items

  if (loading) {
    return (
      <View style={{ justifyContent: 'center', paddingVertical: 30 }}>
        <ActivityIndicator />
      </View>
    )
  }

  if (error) {
    return (
      <View style={{ paddingVertical: 30 }}>
        <Body2 center>{t`thereWasAnErrorLoadingVolunteerRewards`}</Body2>
      </View>
    )
  }

  if (volunteerRewards && volunteerRewards.length <= 0) {
    return null
  }

  const displayedVolunteerRewards = isExpanded ? volunteerRewards : volunteerRewards?.slice(0, 3)
  return (
    <>
      <VolunteerRewardsTable volunteerRewards={displayedVolunteerRewards as VolunteerReward[]} />
      {volunteerRewards && volunteerRewards.length > 3 && (
        <Touchable
          onPress={() => {
            setIsExpanded(!isExpanded)
          }}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 10,
            paddingBottom: 10,
          }}
        >
          <Body2 weight="500" colour={colors.brandColor}>
            {isExpanded ? t`seeLess` : t`seeAll`}
          </Body2>
        </Touchable>
      )}
    </>
  )
}
