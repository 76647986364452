import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Alert } from '@mui/material'

import { FetchedProviderData } from '../actions'
import { BASE_SPACING } from '../constants'

interface Props {
  fetchedProviderData?: FetchedProviderData
}

export const DonationToChaptersWarningMessage = ({ fetchedProviderData }: Props) => {
  const { t } = useTranslation('donateScreen')

  if (!(fetchedProviderData && fetchedProviderData.donateViaNonprofits?.length > 0)) {
    return null
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {fetchedProviderData.donateViaNonprofits.map((donateViaNonprofit) => {
        if (!donateViaNonprofit.routed) {
          return null
        }
        return (
          <Alert
            key={donateViaNonprofit.payeeId}
            variant="outlined"
            severity="info"
            style={{ marginTop: BASE_SPACING * 0.75 }}
          >
            <Trans
              t={t}
              i18nKey="donationToChaptersWarningMessage"
              values={{
                parent: donateViaNonprofit.name ? `, ${donateViaNonprofit.name}` : '',
                child: donateViaNonprofit.childname || t`child`,
              }}
              components={{
                BoldText: <span style={{ fontWeight: 500 }} />,
              }}
            />
          </Alert>
        )
      })}
    </>
  )
}
