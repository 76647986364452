import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { View } from 'react-primitives'

import { styled } from 'src/theme/styled'
import { useInjectEpics } from 'src/utils/injectEpics'
import epics from 'src/containers/modules/Search/epics'
import reducer from 'src/containers/modules/Search/reducer'
import { useInjectReducer } from 'src/utils/injectReducer'
import { EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import Icon from 'src/retired/shared/Icon'
import { Row } from 'src/retired/elements'
import { Body1 } from 'src/retired/shared/Typography'
import { useHistory, useLocation } from 'src/navigation'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { SearchInput } from 'src/components/SearchInput/SearchInput'

import { addSearchDataToUrl, SearchParams, TabType, useSearchParams } from './utils'

const storeSearchTermDebounced = _.debounce((callback: () => void) => {
  callback()
}, 500)

const onKeyUp = (callback: () => void) => {
  storeSearchTermDebounced(callback)
}

interface Props {
  theme: EmotionTheme
}
const Background = styled.View`
  background-color: ${({ theme }: Props) => theme.colors.mediumPurple};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }: Props) => (theme.metrics.isSmall ? '27px 27px 50px 27px' : '37px 37px 37px 110px')};
`
const BackgroundCenter = styled(Row)(({ theme }: Props) => ({
  width: '100%',
  maxWidth: theme.metrics.maxWidthWide,
  margin: 'auto',
  alignItems: 'center',
}))

const Headline = styled(Body1)`
  font-size: ${({ theme }: Props) => (theme.metrics.isSmall ? '16px' : '32px')};
  font-weight: 400;
  padding-left: ${({ theme }: Props) => (theme.metrics.isSmall ? '10px' : '48px')};
`

const InputWrapper = styled.View`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: -32px;
  padding: ${({ theme }: Props) => (theme.metrics.isSmall ? '0 20px' : '0')};
`

export const TopBar = () => {
  useInjectReducer({ key: 'search', reducer })
  useInjectEpics({ key: 'search', epics })

  const { t } = useTranslation('searchScreen')
  const { metrics } = useDeedTheme()
  const history = useHistory()

  const { search } = useLocation()
  const { searchTerm, activeTab } = useSearchParams()

  const user = useSelector(selectCurrentUser)
  const isDonationFeatureEnabled = user?.hasFeature('donations')
  const isFundraiserFeatureEnabled = user?.hasFeature('fundraisers')

  const [currentSearchTerm, setCurrentSearchTerm] = useState<string | undefined>(searchTerm)

  const addSearchTermToUrl = (newSearchTerm: string) => {
    addSearchDataToUrl({ [SearchParams.searchTerm]: newSearchTerm }, search, history)
  }

  const getByPlaceholderText = () => {
    if (metrics.isSmall) {
      return t('searchPlaceholderShort')
    }

    if (activeTab === TabType.volunteer) {
      return t('searchVolunteerLong')
    }

    return isDonationFeatureEnabled && isFundraiserFeatureEnabled ? t('searchPlaceholderLong') : t`searchOrganizations`
  }

  return (
    <>
      <Background>
        <BackgroundCenter>
          <Icon icon="searchIllustration" width={metrics.isSmall ? 145 : 330} height={metrics.isSmall ? 50 : 110} />
          <Headline>{t('headline')}</Headline>
        </BackgroundCenter>
      </Background>
      <InputWrapper>
        <View style={{ width: metrics.isSmall ? 335 : 550 }}>
          <SearchInput
            fieldName="newSearch"
            placeholder={getByPlaceholderText()}
            inputValue={currentSearchTerm || ''}
            onChange={(_fieldName, value) => {
              setCurrentSearchTerm(value)
              onKeyUp(() => {
                addSearchTermToUrl(value)
              })
            }}
            onReset={() => {
              setCurrentSearchTerm('')
              addSearchTermToUrl('')
            }}
            triggerSearch={() => {
              addSearchTermToUrl(currentSearchTerm)
            }}
          />
        </View>
      </InputWrapper>
    </>
  )
}
