import React from 'react'
import { useTranslation } from 'react-i18next'

import { ActivityStatus, useActivitiesQuery } from 'src/generated/graphql'
import User from 'src/entities/user/model'
import { ActivityIndicator } from 'src/retired/elements'
import { Body2, H5 } from 'src/retired/shared/Typography'
import { Link } from 'src/navigation'
import { useDeedTheme } from 'src/theme/ThemeProvider'
import { CardCompactEmpty } from 'src/components/CardCompactEmpty/CardCompactEmpty'

import { Section, SectionContent, SectionTitle, SectionLink } from '../Profile.styled'

import { ActionCardsRow } from './ActionCardsRow'

// currentDate is outside the component, otherwise the value will change on every re-render
// and produce an infinite loop of gql calls
const currentDate = new Date()

export const ActionsSections = ({ user }: { user?: User }) => {
  const isActionsFeatureEnabled = user?.hasFeature('actions')
  const { t } = useTranslation('profileScreen')
  const { colors } = useDeedTheme()
  const {
    data: completedActionsData,
    error: completedActionsError,
    loading: completedActionsLoading,
  } = useActivitiesQuery({
    variables: {
      where: {
        status: { equals: ActivityStatus.Completed },
        userId: { equals: user?.id },
      },
      take: 10,
    },
    skip: !isActionsFeatureEnabled,
  })

  const {
    data: joinedActionsData,
    error: joinedActionsError,
    loading: joinedActionsLoading,
  } = useActivitiesQuery({
    variables: {
      where: {
        status: { equals: ActivityStatus.Joined },
        userId: { equals: user?.id },
        Action: {
          is: {
            OR: [
              {
                endingAt: { gte: currentDate },
              },
              {
                endingAt: { equals: null },
              },
            ],
          },
        },
      },
      take: 10,
    },
    skip: !isActionsFeatureEnabled,
  })

  if (!isActionsFeatureEnabled || joinedActionsError || completedActionsError) {
    return null
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const ActionSection = ({ type }: { type: 'inProgress' | 'completed' }) => {
    const inProgress = type === 'inProgress'
    const isLoading = inProgress ? joinedActionsLoading : completedActionsLoading
    const activities = inProgress ? joinedActionsData?.activities : completedActionsData?.activities
    return (
      <Section>
        <SectionTitle>
          <H5>{inProgress ? t`actionsInProgress` : t`actionsCompleted`}</H5>
        </SectionTitle>
        <SectionContent>
          {isLoading ? (
            <ActivityIndicator />
          ) : activities?.length ? (
            <ActionCardsRow activities={activities} />
          ) : (
            <CardCompactEmpty cardTitle={t`youHaveNoCompletedActions`} link="/actions" buttonText={t('joinAnAction')} />
          )}
        </SectionContent>
        {!isLoading && !!activities?.length && (
          <SectionLink>
            <Link to="/actions">
              <Body2 weight="500" colour={colors.brandColor}>{t`seeAllActions`}</Body2>
            </Link>
          </SectionLink>
        )}
      </Section>
    )
  }

  return (
    <>
      <ActionSection type="inProgress" />
      <ActionSection type="completed" />
    </>
  )
}
