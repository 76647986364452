import { Alert, AlertTitle, Box } from '@mui/material'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

import { showErrorAction } from 'src/containers/modules/Alerts/actions'
import { NonprofitIdentifierType, useNonprofitAvailabilityQuery } from 'src/generated/graphql'
import { ActivityIndicator, ExternalLink } from 'src/retired/elements'
import { stripNonDigits } from 'src/utils/stripNonDigits'
import useDebounce from 'src/utils/useDebounce'

interface Props {
  identifierValue?: string
  identifierInvalid?: boolean
  identifierType?: NonprofitIdentifierType
  skip?: boolean
}

/**
 * @NOTE-DP: you probably hate me for wrapping it as a hook instead of a component, but that's the best way I could encapsulate `blockSubmit` without moving the `useNonprofitAvailabilityQuery` into the parent component
 */
export const useAvailabilityNotice = ({ identifierValue, identifierType, identifierInvalid, skip }: Props) => {
  const { t } = useTranslation('nominateNonprofitSearchForm')
  const dispatch = useDispatch()

  const identifierValueDebounced = useDebounce(identifierValue, 500)
  const identifierInvalidDebounced = useDebounce(identifierInvalid, 500)

  const { data, loading } = useNonprofitAvailabilityQuery({
    variables: {
      identifierValue:
        // @NOTE-DP: both EIN and NCES only contain digits
        stripNonDigits(identifierValueDebounced || ''),
      identifierType: identifierType || NonprofitIdentifierType.Ein,
    },
    skip:
      // @NOTE-DP: both of these values need to be debounced to stay in sync
      !identifierValueDebounced || identifierInvalidDebounced || skip,
    onError: () => {
      dispatch(showErrorAction(t`common:anErrorOccurred`))
    },
  })

  const availability = data?.nonprofitAvailability

  if (loading) {
    return {
      element: (
        <View style={{ marginTop: 10 }}>
          <ActivityIndicator />
        </View>
      ),
      blockSubmit: true,
    }
  }

  if (!availability?.exists) {
    return { element: <View style={{ height: 20 }} />, blockSubmit: false }
  }

  if (availability.filteredOut) {
    return {
      element: (
        <Alert severity="error" style={{ marginTop: 10 }}>
          <AlertTitle>{t`organizationFilteredOutTitle`}</AlertTitle>
          {t`organizationFilteredOutMessage`}
        </Alert>
      ),
      blockSubmit: true,
    }
  }

  const link = availability.multiple
    ? `/search?location=US&searchTerm=${identifierValueDebounced}`
    : `/organization/${availability.nonprofitId}`

  return {
    element: (
      <Alert severity="error" style={{ marginTop: 10 }}>
        <AlertTitle>{t`organizationExistsTitle`}</AlertTitle>
        <div>{t`organizationExistsMessage`}</div>
        <Box my={1}>
          <Link style={{ textDecoration: 'underline', color: '#663225', fontWeight: 500 }} to={link}>
            {availability.multiple ? t`viewSearchResults` : t`viewNonprofitProfile`}
          </Link>
        </Box>
        <div>
          <Trans
            t={t}
            i18nKey="organizationErrorMessage"
            components={{
              a: (
                <ExternalLink
                  href="mailto:nonprofits@joindeed.com"
                  size={15}
                  lineHeight={24}
                  style={{ textDecorationLine: 'underline' }}
                />
              ),
            }}
          />
        </div>
      </Alert>
    ),
    blockSubmit: true,
  }
}
