/* global __DETECTED_VIEWER_TIME_ZONE__ */
import 'core-js/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture'

import { Platform } from 'src/utils'
import config from 'src/config'
import Root from 'src/Root'
import 'src/theme/loadWebFont'

const ignoreUrls = [
  'https://api.segment.io/v1/t',
  'https://api.segment.io/v1/p',
  'https://cdn.acsbapp.com/cache/app/kyndryl.joindeed.org/config.json',
  /https?:\/\/api\.segment\.io/,
  /https?:\/\/scdn\.joindeed\.org/,
  /https?:\/\/sapi\.joindeed\.org/,
  /https?:\/\/((cdn)\.)?acsbapp\.com.+\/config\.json/,
]

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.environment,
    ignoreErrors: [
      'Detected container element removed from DOM',
      'Illegal invocation',
      'Attempted to send event papercups:plan with payload {"plan":null} before iframeRef was ready',
      'Detected popup close',
      'Window is closed, can not determine type',
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:/,
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
      "Failed to read the 'sessionStorage' property from 'Window': Access is denied for this document.",
      'Failed to load https://www.googletagmanager.com/gtag/',
    ],
    ignoreUrls,
    denyUrls: ignoreUrls,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
    normalizeDepth: 10,
  })
}

if (typeof __DETECTED_VIEWER_TIME_ZONE__ === 'undefined' || !__DETECTED_VIEWER_TIME_ZONE__) {
  // eslint-disable-next-line no-global-assign
  __DETECTED_VIEWER_TIME_ZONE__ = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'America/New_York'
}

if (Platform.OS === 'ios') {
  SuppressLongpressGesture.activateService()
}

ReactDOM.render(<Root />, document.getElementById('app'))
