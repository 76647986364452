import i18n from 'i18next'

import config from 'src/config'
import Api from 'src/entities/api'
import * as Sentry from 'src/utils/Sentry'

export const downloadFileFromApi = (url: string, fileName?: string) => {
  void fetch(`${config.apiEndpoint}/${url}`, {
    method: 'GET',
    headers: {
      Authorization: Api.defaultHeaders.Authorization,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(i18n.t('common:downloadFileError'))
      }
      return response.blob()
    })
    .then((blob) => {
      const objectUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = objectUrl
      if (fileName) {
        a.download = fileName
      }
      a.click()
      window.URL.revokeObjectURL(url)
    })
    .catch((error) => {
      Sentry.captureException(error, {
        level: Sentry.Severity.Warning,
        extra: {
          url,
          fileName,
        },
      })
      alert(error.message)
    })
}
