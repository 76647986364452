import jwtDecode, { JwtPayload } from 'jwt-decode'
import * as Sentry from '@sentry/react'

export const expirationDatePerToken: Record<string, number | null> = {}

export const isTokenExpired = (token: string) => {
  try {
    if (!token) {
      return false
    }

    let expirationDate = expirationDatePerToken[token]

    if (!expirationDate) {
      const decodedToken: JwtPayload = jwtDecode(token)

      if (!decodedToken || !decodedToken.exp) {
        return false
      }

      expirationDate = decodedToken.exp * 1000
      expirationDatePerToken[token] = expirationDate
    }

    return expirationDate <= Date.now()
  } catch (error) {
    Sentry.captureException(error, { level: Sentry.Severity.Warning })
    return false
  }
}
