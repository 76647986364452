import React from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-primitives'
import { useSelector } from 'react-redux'

import { InformationCard } from 'src/components'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useBadgesQuery } from 'src/generated/graphql'
import { ActivityIndicator } from 'src/retired/elements'
import { useDeedTheme } from 'src/theme/ThemeProvider'

import { BadgeWithTitle } from '../BadgeWithTitle'

export const BadgesWidget = ({ style }: { style: React.CSSProperties }) => {
  const { colors, metrics } = useDeedTheme()
  const { t } = useTranslation('badges')

  const user = useSelector(selectCurrentUser)
  const isBadgesFeatureEnabled = user?.hasFeature('badges')
  const isVolunteeringFeatureEnabled = user?.hasFeature('volunteering')
  const {
    data: badgesData,
    error: badgesError,
    loading: badgesLoading,
  } = useBadgesQuery({
    skip: !isBadgesFeatureEnabled,
  })

  if (!isVolunteeringFeatureEnabled) {
    return null
  }

  if (isBadgesFeatureEnabled && badgesLoading) {
    return (
      <InformationCard style={style} childrenStyle={{ flex: 1 }}>
        <ActivityIndicator color={colors.brandColor} />
      </InformationCard>
    )
  }

  const allBadges = badgesData?.badges
  const awardedBadges = allBadges?.filter((badge) => badge.awardedBadge) ?? []

  if (awardedBadges.length && !badgesError) {
    const [recentAwardedBadge] = awardedBadges.sort((a, b) => {
      if (b.awardedBadge && a.awardedBadge) {
        const awardedAtTheSameTime = b.awardedBadge.earnedAt === a.awardedBadge.earnedAt
        if (!awardedAtTheSameTime) {
          return new Date(b.awardedBadge.earnedAt).getTime() - new Date(a.awardedBadge.earnedAt).getTime()
        }

        const sameCategoryAndSameUnit = b.category === a.category && b.criteria[0]?.unit && a.criteria[0]?.unit
        if (sameCategoryAndSameUnit) {
          // return the higher rating amount (means the best badge is shown)
          return b.criteria[0].amount - a.criteria[0].amount
        }
      }
      return -1
    })

    return (
      <InformationCard
        style={{ flex: metrics.isSmall ? undefined : 1, ...style }}
        title={recentAwardedBadge.name}
        buttonText={t`browseOpportunities`}
        buttonTo="/search?tab=volunteer"
        secondaryButtonText={t`viewAllBadges`}
        secondaryButtonTo="/profile/badges"
      >
        <View style={{ marginTop: 24, alignItems: 'center' }}>
          <BadgeWithTitle
            badge={recentAwardedBadge}
            withEarnedSubtitle
            imageWidth={metrics.isSmall ? 92 : 112}
            subtitleStyle={{ fontSize: metrics.isSmall ? 12 : 16 }}
            hideBadgeTitle
            maxWidth="100%"
          />
        </View>
      </InformationCard>
    )
  }

  return (
    <InformationCard
      style={{ flex: metrics.isSmall ? undefined : 1, ...style }}
      title={t`volunteer`}
      subtitle={t`findYourNextGoodDeed`}
      buttonText={t`browseOpportunities`}
      buttonTo="/search?tab=volunteer"
      bottomText={t`startBrowsing`}
      bottomTextIconName="heartPersonMessage"
    />
  )
}
