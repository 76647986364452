import { DonationProvider } from '@joindeed/calculate-fees'

import Organization from 'src/entities/organization/model'

import { PayPalModel } from './constants'

export const getPayPalModel = ({
  donationProvider,
  firstNonprofit,
}: {
  donationProvider: DonationProvider | null
  firstNonprofit?: Organization
}): PayPalModel | null => {
  if (donationProvider !== DonationProvider.PayPal) {
    return null
  }
  return firstNonprofit?.payPalModel || null
}
