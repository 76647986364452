/* eslint-disable react/no-unstable-nested-components */
import React from 'react'
import { View } from 'react-primitives'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { CurrencySelectorTooltip } from 'src/containers/modules/CurrencyFormat'
import Deed from 'src/entities/deed/model'
import { Spacing } from 'src/retired/elements'
import Chip from 'src/retired/shared/Chip'
import { formatNumber } from 'src/utils/format'
import Organization from 'src/entities/organization/model'

import { Box, CampaignBar } from '.'

export const CampaignBlock = ({
  deed,
  userBrand,
  style = {},
}: {
  deed: Deed
  userBrand: Organization | undefined
  style?: React.CSSProperties
}): React.ReactNode => {
  const { t } = useTranslation('deedScreen')

  if (deed?.type !== 'Campaign') {
    return null
  }
  const baseCurrency = deed.currencyCode || 'USD'

  return (
    <Box style={style} half={deed.donations.size > 0}>
      <View style={{ alignSelf: 'stretch' }}>
        <View style={{ maxWidth: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CampaignBar
            organization={userBrand || deed?.partner}
            amountCurrencies={deed.goalAmountCurrencies}
            donationStats={deed.donationStats}
            baseCurrency={baseCurrency}
            deed={deed}
          />
        </View>
        {deed.matchingPercentage &&
        ((deed.matchingMaximumCurrencies &&
          (deed.matchingMaximumCurrencies[baseCurrency] > 0 ||
            _.isNil(deed.matchingMaximumCurrencies[baseCurrency]))) ||
          _.isNil(deed.matchingMaximumCurrencies)) ? (
          <Spacing marginTop={10} marginBottom={15} style={{ alignSelf: 'center' }}>
            <Chip type="square" backgroundColor="yellow">
              {`${t('donationsMatched', {
                percentage: `${formatNumber(deed.matchingPercentage / 100)}X`,
              })}`.toUpperCase()}
            </Chip>
          </Spacing>
        ) : null}
        <View style={{ alignItems: 'flex-end', width: '100%', paddingBottom: 15 }}>
          <CurrencySelectorTooltip text={t`changeMyCurrency`} />
        </View>
      </View>
    </Box>
  )
}
