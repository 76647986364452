import { Record as IRecord, Map } from 'immutable'
import _ from 'lodash'
import type { DonationProvider, PaymentMethodType } from '@joindeed/calculate-fees'

import { AmountCurrencies } from 'src/containers/modules/CurrencyFormat'
import config from 'src/config'
import LocationLatLng from 'src/entities/locationLatLng/model'
import LocationObject from 'src/entities/locationObject/model'
import Location from 'src/entities/location/model'
import Cause from 'src/entities/cause/model'
import Deed from 'src/entities/deed/model'
import { type MatchableRule } from 'src/entities/donation/matchableRule'

interface Stripe {
  userId: string
  customerId: string
  accountCountry?: string
}

export interface TypeOptions {
  title: string
}

export enum InputType {
  'Radio' = 'Radio',
}

export interface Question {
  _id: string
  title: string
  inputType: InputType
  typeOptions: TypeOptions[]
  isRequired: boolean
}

type Settings = Record<string, boolean> & {
  corporateOnlyToggleDefault?: boolean
  preferStripe?: boolean
  deedFeeCapUSD?: number
  providerFeeCapUSD?: number
  applicationFees: {
    employee?: number
    corporate?: number
  }
  paypalDisableFunding?: string[]
  enableGuestUsers?: boolean
  allowVtoForExternalDeed?: boolean
  searchNonprofitDB?: boolean
  volunteerReward?: {
    validationForm?: string
    fallbackNonprofit?: string
  }
  deedFeedLocationFilter?: boolean
  additionalVolunteerTimeQuestions?: Question[]
}

interface SSO {
  provider: string
  identifier: string
}

interface NotificationBanner {
  isActive: boolean
  bannerText: string
  linkIsActive: boolean
  linkText: string
  linkUrl: string
  backgroundColor: string
  textColor: string
}

type DisclaimerContentType = 'IMAGE' | 'TEXT'

interface Disclaimer {
  _id: string
  contentType: DisclaimerContentType
  content: string
  buttonText?: string
  disclaimerExpire: number // Disclaimer expire in minutes
  locationsExclude: boolean
  locations: string[]
  startingAt?: Date
  endingAt?: Date
}

export enum PayPalCharityType {
  PPGF_ENROLLED = 'PPGF_ENROLLED',
  PPGF_UNENROLLED = 'PPGF_UNENROLLED',
  DIRECT_MODEL_ENROLLED = 'DIRECT_MODEL_ENROLLED',
}

type DonationProviderMap = Partial<{
  [provider in DonationProvider]: boolean
}>

const properties = {
  id: '',
  nonprofitId: '',
  type: '',
  name: '',
  code: '',
  email: '',
  ein: '',
  phone: '',
  pictures: [],
  mainPicture: '',
  logo: '',
  banner: '',
  website: '',
  location: '',
  locationLatLng: LocationLatLng,
  locationObject: LocationObject,
  locations: Map(),
  rating: 0,
  matchingPercentage: 0,
  matchingMaximumCurrencies: {},
  matchableRule: undefined,
  matchableRules: undefined,
  matchingPaymentMethods: [],
  matchingPaymentMethodsExclude: false,
  matchingBudgetId: undefined,
  brief: '',
  tagLine: '',
  modifiedAt: {},
  createdAt: {},
  reviews: {},
  deeds: [],
  departments: [],
  facebookPage: '',
  instagram: '',
  pillars: [],
  ERGs: [],
  categories: [],
  SDGs: [],
  disclaimer: null,
  sso: {},
  sso2: {}, // temporary workaround for not displaying companies on native
  providers: [],
  emailDomains: [],
  brandColor: '',
  stripe: {},
  applicationFee: undefined,
  followersCount: 0,
  matchForSearchTerm: '',
  searchTermMatchScore: 0,
  externalId: '',
  externalRatings: {},
  donationAmountOptions: undefined,
  homepageBanner: '',
  homepageGradient: true,
  homepageTagline: '',
  hideHomepageTagline: false,
  settings: {},
  acceptsDonations: false,
  currencyCode: 'USD',
  payPalModel: undefined,
  faqLink: '',
  approved: false,
  notificationBanner: {},
  feeCovered: undefined,
  paypalCharityType: undefined,
  appVanityDomain: undefined,
  splashBackgroundImage: undefined,
  mergeApi: undefined,
  hasHrisIntegration: undefined,
  identifiers: [],
  donationProvider: null,
  donationProvidersAvailability: {},
  isPartnerNonprofit: false,
  donateViaNonprofitId: undefined,
  nonprofitEntityType: '',
  __typename: 'Organization',
}

export default class Organization extends IRecord(properties, 'Organization') implements Organization {
  public readonly id!: string

  public readonly nonprofitId!: string

  public readonly type!: string

  public readonly name!: string

  public readonly code!: string

  public readonly email!: string

  public readonly phone!: string

  public readonly pictures!: string[]

  public readonly mainPicture!: string | null

  public readonly logo!: string | null

  public readonly banner!: string

  public readonly website!: string

  public readonly location!: string

  public readonly locationLatLng!: LocationLatLng

  public readonly locationObject!: LocationObject

  public readonly locations!: Map<string, Location>

  public readonly rating!: number

  public readonly matchingPercentage!: number

  public readonly matchingMaximumCurrencies!: AmountCurrencies

  public readonly matchableRule!: MatchableRule

  public readonly matchableRules?: Record<string, MatchableRule>

  public readonly matchingPaymentMethods!: [PaymentMethodType]

  public readonly matchingPaymentMethodsExclude!: boolean

  public readonly matchingBudgetId!: string

  public readonly brief!: string

  public readonly tagLine!: string

  public readonly modifiedAt!: Date

  public readonly createdAt!: Date

  public readonly reviews!: any

  public readonly deeds!: Deed[]

  public readonly departments!: string[]

  public readonly facebookPage!: string

  public readonly instagram!: string

  public readonly ein!: string

  public readonly ncesId!: string

  public readonly pillars!: string[]

  public readonly ERGs!: string[]

  public readonly categories!: string[]

  public readonly SDGs!: string[]

  public readonly disclaimer!: Disclaimer

  public readonly sso!: SSO

  public readonly sso2!: SSO

  public readonly providers!: string[]

  public readonly emailDomains!: string[]

  public readonly brandColor!: string

  public readonly stripe!: Stripe

  public readonly settings!: Settings

  public readonly applicationFee!: number

  public readonly followersCount!: number

  public readonly matchForSearchTerm!: string

  public readonly searchTermMatchScore!: number

  public readonly externalId!: string

  public readonly donationAmountOptions!: number[]

  public readonly externalRatings!: any

  public readonly homepageBanner!: string

  public readonly homepageGradient!: string

  public readonly homepageTagline!: string | null

  public readonly hideHomepageTagline!: boolean

  public readonly acceptsDonations!: boolean

  public readonly currencyCode!: string

  public readonly payPalModel!: 'PPGF' | 'DIRECT' | 'COMMERCE'

  public readonly faqLink!: string

  public readonly approved!: boolean

  public readonly notificationBanner!: NotificationBanner

  public readonly feeCovered!: boolean

  public readonly paypalCharityType!: PayPalCharityType

  public readonly appVanityDomain!: string

  public readonly splashBackgroundImage!: string

  public readonly donationProvider?: DonationProvider

  public readonly donationProvidersAvailability?: Record<DonationProvider, boolean>

  public readonly isPartnerNonprofit?: boolean

  public readonly donateViaNonprofitId?: string

  public readonly nonprofitEntityType?: string

  public readonly mergeApi?: {
    provider: string
  }

  public readonly identifiers?: Array<{ id: string; countryCode: string; type: string }>

  public readonly donationProviders?: DonationProviderMap

  public readonly __typename!: 'Organization' | 'DeedNonprofit'

  constructor(values: any = {}) {
    const locationObject = new LocationObject(values.locationObject)
    const validValues = _.pick(values, Object.keys(properties))
    super({
      ...validValues,
      type: values.__t || values.type,
      __typename: values.__typename ?? 'Organization',
      id: values._id || values.id,
      ein: values.ein ?? values.identifiers?.find((i) => i.type === 'ein')?.id ?? '',
      ncesId: values.identifiers?.find((i) => i.type === 'nces')?.id ?? '',
      locationLatLng: new LocationLatLng(values.location),
      locationObject,
      location: locationObject?.toString(false) || '',
      locations:
        values.locations && Map(values.locations.map((location: any) => [location.id, new Location(location)])),
      deeds: (values.deeds && values.deeds.map((deed: any) => new Deed(deed))) || [],
      donationProvidersAvailability: !_.isEmpty(values.donationProvidersAvailability)
        ? values.donationProvidersAvailability
        : !_.isEmpty(values.donationProviders)
        ? values.donationProviders
        : values.donationProvider
        ? { [values.donationProvider]: true }
        : {},
      disclaimer: values.disclaimers?.[0],
    })
  }

  public hasCause(cause: Cause): boolean {
    const causeField = cause.fieldName()
    return this.get(causeField).includes(cause.get('id'))
  }

  public isCompany(): boolean {
    return this.type.toLowerCase() === 'company'
  }

  public isNonprofit(): boolean {
    return this.type.toLowerCase() === 'nonprofit'
  }

  public isExternal(): boolean {
    return !!this.externalId && !this.id
  }

  public canDonate(): boolean {
    if (this.acceptsDonations) {
      return true
    }

    if (!this.isExternal()) {
      return !!this.stripe.userId
    }

    return Boolean(this.acceptsDonations)
  }

  public formattedEin(): string {
    const ein = (this.ein || '').replace(/[\s-]/g, '').trim()
    return this.locationObject?.countryCode === 'US' && ein ? `${ein.substr(0, 2)}-${ein.substr(2)}` : ein || ''
  }

  get appDomain(): string {
    return this.appVanityDomain || new URL(config.webUrl).host
  }
}
