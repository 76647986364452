import React, { useState } from 'react'
import { PaymentMethodType, DonationProvider } from '@joindeed/calculate-fees'
import { type TFunction, useTranslation } from 'react-i18next'

import { Image, Spacing, TabsPicker } from 'src/retired/elements'
import { H5 } from 'src/retired/shared/Typography'
import { type EmotionTheme, useDeedTheme } from 'src/theme/ThemeProvider'
import type User from 'src/entities/user/model'
import SelectBox from 'src/retired/shared/SelectBox'
import type Campaign from 'src/entities/campaign/model'
import { PayrollDonationFrequencyOptions, PayrollPaymentOption } from 'src/generated/graphql'

import { BASE_SPACING, DonationFrequency, type PaymentType, type PayPalModel } from './constants'

const PAYPAL_MODELS_WITH_UNSUPPORTED_FREQUENCY = ['COMMERCE', 'DIRECT'] as PayPalModel[]

export const useFrequency = ({
  user,
  type: entityTypeDonatingTo,
  donationProvider,
  payPalModel,
  campaign,
  paymentType,
  selectedPayrollPaymentOption,
}: {
  user?: User
  type: 'organization' | 'deed'
  donationProvider: DonationProvider | null
  payPalModel: PayPalModel
  campaign?: Campaign
  paymentType: PaymentType
  selectedPayrollPaymentOption?: PayrollPaymentOption
}) => {
  const [selectedFrequency, setSelectedFrequency] = useState<DonationFrequency | PayrollDonationFrequencyOptions>(
    DonationFrequency.Onetime
  )

  const currentPaymentOptionIsPayroll = paymentType === PaymentMethodType.Payroll

  const recurringDonationFeatureEnabled = user?.hasFeature('recurringDonations')

  // Fundraisers are not permitted to be donated to with recurring
  const donatingToAFundraiser = entityTypeDonatingTo === 'deed'

  const supportedPayPalModelFrequency =
    donationProvider === DonationProvider.PayPal && !PAYPAL_MODELS_WITH_UNSUPPORTED_FREQUENCY.includes(payPalModel)

  const nonPayrollFrequencyOptions: DonationFrequency[] = [DonationFrequency.Onetime]

  if (supportedPayPalModelFrequency && !donatingToAFundraiser && recurringDonationFeatureEnabled) {
    nonPayrollFrequencyOptions.push(DonationFrequency.Monthly)
  }

  const pledgeCampaignOptions = campaign?.isPledge
    ? ([
        PayrollDonationFrequencyOptions.Monthly,
        campaign?.allowOneTimePledge && PayrollDonationFrequencyOptions.Onetime,
      ] as PayrollDonationFrequencyOptions[])
    : undefined

  const payrollFrequencyOptions: PayrollDonationFrequencyOptions[] | undefined = currentPaymentOptionIsPayroll
    ? pledgeCampaignOptions || selectedPayrollPaymentOption?.frequencyOptions
    : undefined

  const frequencyOptions = payrollFrequencyOptions || nonPayrollFrequencyOptions

  const donationFrequency = frequencyOptions.includes(selectedFrequency as never)
    ? selectedFrequency
    : frequencyOptions[0] || DonationFrequency.Onetime

  return { donationFrequency, frequencyOptions, setSelectedFrequency }
}

type OptionProps = {
  paymentType: PaymentType
  theme: EmotionTheme
  frequency: DonationFrequency
  t: TFunction<'donateScreen'>
}

const FrequencyOption = ({ paymentType, theme, frequency, t }: OptionProps): React.ReactElement => {
  const { images, metrics } = theme
  if (paymentType === PaymentMethodType.Payroll) {
    return frequency === DonationFrequency.Monthly ? (
      <>
        {metrics.isSmall ? (
          t`everyPaycheck`
        ) : (
          <>
            <Image
              source={images.smilingFaceWithHeartEyes}
              style={{ width: 14, height: 14, verticalAlign: 'middle' }}
            />{' '}
            {t`giveEveryPaycheck`}
          </>
        )}
      </>
    ) : (
      <>
        {!metrics.isSmall && (
          <>
            <Image source={images.smileyBlush} style={{ width: 14, height: 14, verticalAlign: 'middle' }} />{' '}
          </>
        )}
        {t`onetime`}
      </>
    )
  }
  return frequency === DonationFrequency.Monthly ? t`monthly` : t`onetime`
}

type FrequencyInputProps = {
  paymentType: PaymentType
  frequencyOptions: DonationFrequency[]
  donationFrequency: DonationFrequency
  setDonationFrequency: (value: DonationFrequency) => void
}

export const FrequencyInput = ({
  paymentType,
  donationFrequency,
  setDonationFrequency,
  frequencyOptions,
}: FrequencyInputProps) => {
  const { t } = useTranslation('donateScreen')
  const theme = useDeedTheme()
  return (
    <>
      <Spacing marginBottom={BASE_SPACING} />

      <H5 weight="500" marginBottom={BASE_SPACING}>{t`frequency`}</H5>

      <TabsPicker
        options={frequencyOptions.map((frequency) => ({
          value: frequency,
          name: <FrequencyOption frequency={frequency} paymentType={paymentType} theme={theme} t={t} />,
        }))}
        selected={donationFrequency}
        onOptionSelect={(value) => setDonationFrequency(value as DonationFrequency)}
      />
    </>
  )
}

export const SelectFrequency = ({
  frequencyOptions,
  donationFrequency,
  paymentType,
  setDonationFrequency,
}: FrequencyInputProps) => {
  const { t } = useTranslation('donateScreen')
  const { images } = useDeedTheme()
  return (
    <SelectBox
      onSelect={(value: DonationFrequency) => setDonationFrequency(value)}
      value={donationFrequency}
      options={frequencyOptions.map((value) => ({
        value,
        title:
          value === DonationFrequency.Monthly ? (
            paymentType === 'Payroll' ? (
              <>
                <Image
                  source={images.smilingFaceWithHeartEyes}
                  style={{
                    width: 14,
                    height: 14,
                    verticalAlign: 'sub',
                    display: 'inline-block',
                    marginRight: 10,
                  }}
                />
                {t`giveEveryPaycheck`}
              </>
            ) : (
              t`monthly`
            )
          ) : (
            <>
              {paymentType === 'Payroll' && (
                <Image
                  source={images.smileyBlush}
                  style={{
                    width: 14,
                    height: 14,
                    verticalAlign: 'sub',
                    display: 'inline-block',
                    marginRight: 10,
                  }}
                />
              )}
              {t`onetime`}
            </>
          ),
      }))}
    />
  )
}
