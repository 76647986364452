import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { getHours } from 'src/utils/convertDuration'
import { selectCurrentUser } from 'src/entities/user/selectors'
import { useVolunteerRewardsUserBudgetQuery } from 'src/generated/graphql'
import { ActivityIndicator } from 'src/retired/elements'
import { Body2, H5 } from 'src/retired/shared/Typography'

import ProgressBar from '../VolunteerTimeSuccess/ProgressBar'

import { VolunteerRewardsSummary } from './VolunteerRewardsSummary'

export const VolunteerRewards = () => {
  const { t } = useTranslation('volunteerRewardsSection')
  const user = useSelector(selectCurrentUser)

  const isVolunteerRewardFeatureEnabled = user?.hasFeature('volunteerReward')

  const { data, loading, error } = useVolunteerRewardsUserBudgetQuery({
    skip: !isVolunteerRewardFeatureEnabled,
    variables: {
      userId: user?.id as string,
    },
  })

  if (!isVolunteerRewardFeatureEnabled) {
    return null
  }

  if (loading || error) {
    return (
      <Box style={{ padding: '30px' }}>
        {loading ? <ActivityIndicator /> : <Body2 center>{t`grantsBudgetError`}</Body2>}
      </Box>
    )
  }

  const volunteerRewardsUserBudget = data?.volunteerRewardsUserBudget
  const limitPerUserInMinutes = volunteerRewardsUserBudget?.limitPerUserInMinutes ?? 0
  const amountApprovedInMinutes = volunteerRewardsUserBudget?.amountApprovedInMinutes ?? 0
  const amountEligiblePendingInMinutes = volunteerRewardsUserBudget?.amountEligiblePendingInMinutes ?? 0

  return (
    <>
      <H5 style={{ paddingHorizontal: 25, paddingVertical: 20 }}>{t`volunteerGrants`}</H5>
      {volunteerRewardsUserBudget && (
        <VolunteerRewardsSummary volunteerRewardsUserBudget={volunteerRewardsUserBudget} />
      )}
      <Box style={{ padding: '0 25px' }}>
        <ProgressBar
          progress1={(amountApprovedInMinutes * 100) / limitPerUserInMinutes}
          progress2={(amountEligiblePendingInMinutes * 100) / limitPerUserInMinutes}
          title={t(`progressBarTitle`, {
            usedAmount: getHours(amountApprovedInMinutes + amountEligiblePendingInMinutes),
            budget: getHours(limitPerUserInMinutes),
          })}
          progress2label={t('progressBarPendingHoursLabel', { hours: getHours(amountEligiblePendingInMinutes) })}
        />
      </Box>
    </>
  )
}
